import { useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

const useAnalytics = () => {
  const analytics = getAnalytics();

  const trackEvent = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
  };

  useEffect(() => {
    // Your initialization code here, if needed.
  }, []);

  return { trackEvent };
};

export default useAnalytics;
