import React, { useState } from "react";
import Logo from "../../Assests/Frame.png";
import Profile from "../../Assests/profile.png";
import "./NavBar.css";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";

const NavBar = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-11 col-md-11">
        <nav className="navbar navbar-expand-lg fixed-top navbar-light py-3">
          <div className="container-fluid">
            {/* <a className="navbar-brand" href="#">
              Navbar
            </a> */}
            <img src={Logo} className="img_logo pe-2" alt="" />
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 px-2">
                <li className="nav-item ps-5 px-4" onClick={() => toggleTab(1)}>
                  <a
                    className={
                      toggleState === 1
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                    href="#"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item px-4" onClick={() => toggleTab(2)}>
                  <a
                    className={
                      toggleState === 2
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                    href="#"
                  >
                    Marketing Fiels
                  </a>
                </li>
                <li className="nav-item px-4" onClick={() => toggleTab(3)}>
                  <a
                    className={
                      toggleState === 3
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                    href="#"
                  >
                    Portfolio
                  </a>
                </li>
                <li className="nav-item px-4" onClick={() => toggleTab(4)}>
                  <a
                    className={
                      toggleState === 4
                        ? "nav-link nav-link-active"
                        : "nav-link"
                    }
                    href="#"
                  >
                    Cendrol Collection
                  </a>
                </li>
              </ul>
              <div
                className="d-flex justify-content-around"
                style={{ gap: "20px" }}
              >
                <div className="search_div" style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="top_menu_search"
                    placeholder="Search Here.."
                    // onChange={(event) => {
                    //   Setsearchterm(event.target.value);
                    // }}
                  />
                  <SearchIcon />
                </div>
                <div className="align-self-center">
                  <NotificationsRoundedIcon />
                </div>
                <div>
                  <img
                    src={Profile}
                    className="img-rounded img-responsive profile_img"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
